import React, { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';

import styles from './MyPagesCustomerServiceBanner.module.scss';

const MyPagesCustomerServiceBanner = ({}) => {
    const { t } = useTranslation();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const actingUserName = getCookie('openid-act-user-name');
    const userName = getCookie('openid-user-name');

    if (!actingUserName || !userName) {
        return;
    }

    return (
        mounted && (
            <div className={styles['MyPagesCustomerServiceBanner']}>
                <div
                    className={
                        styles['MyPagesCustomerServiceBanner__Container']
                    }>
                    <p>
                        {t('MyPagesCustomerServiceBanner.loggedInAs')}{' '}
                        {actingUserName}{' '}
                        {t('MyPagesCustomerServiceBanner.forCustomer')}{' '}
                        {userName}
                    </p>
                </div>
            </div>
        )
    );
};

MyPagesCustomerServiceBanner.propTypes = {};

export default MyPagesCustomerServiceBanner;
