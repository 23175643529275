import React from 'react';
import PropTypes from 'prop-types';

import { ucFirst } from '../../utils/caseconverters';
import classNames from 'classnames';
import { snakeToPascal } from '../../utils/caseconverters';
import dynamic from 'next/dynamic';
import styles from './StreamField.module.scss';

const AccordionInvoiceList = dynamic(() => import('../AccordionInvoiceList'));
const ArticleEntry = dynamic(() => import('../ArticleEntry'));
const BlockQuote = dynamic(() => import('../BlockQuote'));
const CardCta = dynamic(() => import('../CardCta'));
const CardCtaSmallList = dynamic(() => import('../CardCtaSmallList'));
const CardOfferList = dynamic(() => import('../CardOfferList'));
const CardOptionalList = dynamic(() => import('../CardOptionalList'));
const CardPricingList = dynamic(() => import('../CardPricingList'));
const CardTestimonial = dynamic(() => import('../CardTestimonial'));
const Carousel = dynamic(() => import('../Carousel'));
const ConsumptionDiagramMonthly = dynamic(
    () => import('../ConsumptionDiagramMonthly')
);
const ContactUs = dynamic(() => import('../ContactUs'));
const CtaButtonList = dynamic(() => import('../CtaButtonList'));
const Diagrams = dynamic(() => import('../Diagrams'));
const DynamicsForm = dynamic(() => import('../DynamicsForm'));
const EstimatedConsumption = dynamic(() => import('../EstimatedConsumption'));
const Faq = dynamic(() => import('../Faq'));
const FaqList = dynamic(() => import('../FaqList'));
const Form = dynamic(() => import('../InlineForm'));
const GlobalModules = dynamic(() => import('../GlobalModules'));
const GlobalArticleModules = dynamic(() => import('../GlobalArticleModules'));
const HeroFiftyFifty = dynamic(() => import('../HeroFiftyFifty'));
const HeroMyPages = dynamic(() => import('../HeroMyPages'));
const HeroWithButtons = dynamic(() => import('../HeroWithButtons'));
const HeroWithProducts = dynamic(() => import('../HeroWithProducts'));
const HeroSunPark = dynamic(() => import('../HeroSunPark'));
const InlineImage = dynamic(() => import('../InlineImage'));
const InlineCard = dynamic(() => import('../InlineCard'));
const InlineImageSideBySide = dynamic(() => import('../InlineImageSideBySide'));
const InlineImages = dynamic(() => import('../InlineImages'));
const InlineRawHtml = dynamic(() => import('../InlineRawHtml'));
const InlineRichText = dynamic(() => import('../InlineRichText'));
const InlineTable = dynamic(() => import('../InlineTable'));
const InlineVideo = dynamic(() => import('../InlineVideo'));
const InlineVideoSideBySide = dynamic(() => import('../InlineVideoSideBySide'));
const JobEmailListSubscription = dynamic(
    () => import('../JobEmailListSubscription')
);
const JobList = dynamic(() => import('../JobList'));
const JobSelected = dynamic(() => import('../JobSelected'));
const MyPagesErrorList = dynamic(() => import('../MyPagesErrorList'));
const MyPagesFacilityList = dynamic(() => import('../MyPagesFacilityList'));
const MyPagesLoginChoices = dynamic(() => import('../MyPagesLoginChoices'));
const News = dynamic(() => import('../News'));
const NewsArchive = dynamic(() => import('../NewsArchive'));
const Outage = dynamic(() => import('../Outage'));
const ProductCalculator = dynamic(() => import('../ProductCalculator'));
const ProductDiagram = dynamic(() => import('../ProductDiagram'));
const ProductGuide = dynamic(() => import('../ProductGuide'));
const ProductList = dynamic(() => import('../ProductList'));
const PropertyCardList = dynamic(() => import('../PropertyCardList'));
const RawHtml = dynamic(() => import('../RawHtml'));
const ResponsiveImage = dynamic(() => import('../ResponsiveImage'));
const RichText = dynamic(() => import('../RichText'));
const SignupElectricityForm = dynamic(() => import('../SignupElectricityForm'));
const SignupElectricityGridForm = dynamic(
    () => import('../SignupElectricityGridForm')
);
const MoveForm = dynamic(() => import('../MoveForm'));
const SignupFork = dynamic(() => import('../SignupFork'));
const TabbedContent = dynamic(() => import('../TabbedContent'));
const TextColumns = dynamic(() => import('../TextColumns'));
const TotalProductionList = dynamic(() => import('../TotalProductionList'));
const TextColumnWithImage = dynamic(() => import('../TextColumnWithImage'));
const UspList = dynamic(() => import('../UspList'));
const VideoModule = dynamic(() => import('../VideoModule'));

const Components = {
    AccordionInvoiceList,
    ArticleEntry,
    CardCta,
    CardCtaSmallList,
    CardOfferList,
    CardOptionalList,
    CardPricingList,
    CardTestimonial,
    Carousel,
    ConsumptionDiagramMonthly,
    ContactUs,
    CtaButtonList,
    Diagrams,
    DynamicsForm,
    EstimatedConsumption,
    Faq,
    FaqList,
    Form,
    GlobalModules,
    GlobalArticleModules,
    HeroFiftyFifty,
    HeroMyPages,
    HeroWithButtons,
    HeroWithProducts,
    HeroSunPark,
    InlineImage,
    InlineImageSideBySide,
    InlineImages,
    InlineRawHtml,
    InlineRichText,
    InlineTable,
    InlineVideo,
    InlineVideoSideBySide,
    InlineCard,
    JobEmailListSubscription,
    JobList,
    JobSelected,
    MyPagesErrorList,
    MyPagesFacilityList,
    MyPagesLoginChoices,
    News,
    NewsArchive,
    Outage,
    ProductCalculator,
    ProductDiagram,
    ProductGuide,
    ProductList,
    PropertyCardList,
    Quote: BlockQuote,
    RawHtml,
    ResponsiveImage,
    RichText,
    SignupElectricityForm,
    SignupElectricityGridForm,
    MoveForm,
    SignupFork,
    TabbedContent,
    TextColumns,
    TotalProductionList,
    TextColumnWithImage,
    UspList,
    VideoModule,
};

const StreamField = ({
    items = [],
    componentWrapperExtraClass = '',
    heroWithButtons,
}) => {
    if (!items.length) {
        return null;
    }
    const dynamicComponents = items.map((item, index) => {
        item.component = snakeToPascal(item.component);
        const Component = Components[item.component];

        if (!Component) {
            return null;
        }

        const isFirstComponent = index === 0;

        const isFullHeightComponent =
            item.component === 'CardCtaSmallList' ||
            item.component === 'ContactUs' ||
            item.component === 'ProductList' ||
            item.component === 'TextColumns';

        const spaceClass =
            heroWithButtons && isFirstComponent && isFullHeightComponent
                ? 'Space'
                : '';

        return (
            <div
                key={index}
                id={`sf-${item.id}`}
                className={classNames(
                    styles['StreamField__Component'],
                    styles['StreamField__Component--' + item.component],
                    styles[
                        'StreamField__Component--' +
                            ucFirst(componentWrapperExtraClass)
                    ],
                    styles['StreamField__Component--' + spaceClass]
                )}>
                <Component id={item.id} {...item} />
            </div>
        );
    });

    return <>{dynamicComponents}</>;
};

StreamField.propTypes = {
    items: PropTypes.array,
    componentWrapperExtraClass: PropTypes.string,
};

export default StreamField;
